<template>
  <b-modal
    ref="refFormModal"
    title="Seleccione el tipo de cupón"
    hide-footer
    no-close-on-backdrop
    hide-header-close
  >
    <b-list-group v-if="toArray.length>0">
      <b-list-group-item
        v-for="type of toArray"
        :key="type.type"
        class="type-item"
        @click="selectType(type.type)"
      >
        {{ type.text }}
      </b-list-group-item>
    </b-list-group>
  </b-modal>

</template>

<script>
import { BListGroup, BListGroupItem, BModal } from 'bootstrap-vue'

export default {
  name: 'CouponTypeSelectModal',
  components: { BListGroup, BListGroupItem, BModal },
  props: {
    types: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toArray: [],
    }
  },
  methods: {
    show() {
      this.toArray = []
      Object.getOwnPropertyNames(JSON.parse(JSON.stringify(this.types))).forEach(item => {
        this.toArray.push({
          type: item,
          text: this.types[item],
        })
      })
      this.$refs.refFormModal.show()
    },
    selectType(type) {
      this.$emit('selected', type)
      this.$refs.refFormModal.hide()
    },
  },
}
</script>

<style scoped lang="scss">
.type-item {
  cursor: pointer;
}

</style>

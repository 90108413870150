<template>
  <div class="card">
    <coupon-type-selec-modal
      ref="refFormModal"
      :types="types"
      @selected="selectedType"
    />
    <div class="card-body">
      <div v-if="!loading && coupon.type">
        <div class="mb-2">
          <b-badge
            pill
            variant="primary"
          >
            {{ types[coupon.type] }}
          </b-badge>
        </div>
        <h4>Información general</h4>
        <div class="form-group">
          <label>Nombre del cupón (para uso interno)</label>
          <input
            v-model="coupon.name"
            type="text"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <b-form-checkbox
            v-model="coupon.automatic_application"
            switch
          >
            Aplicación automática
          </b-form-checkbox>
        </div>
        <div
          v-if="!coupon.automatic_application"
          class="form-group"
        >
          <label>Código de cupón</label>
          <input
            v-model="coupon.code"
            type="text"
            class="form-control"
          >
        </div>

        <div v-if="coupon.type.toString()!=='3'">
          <div class="row">
            <div class="col-6">
              <div
                class="form-group"
              >
                <label>Tipo de descuento</label>
                <select
                  v-model="coupon.discount_type"
                  class="form-control"
                >
                  <option
                    v-for="discountType of discountTypes"
                    :key="discountType.type"
                    :value="discountType.type"
                  >
                    {{ discountType.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div
                class="form-group"
              >
                <label>Monto de descuento</label>
                <input
                  v-model="coupon.discount_amount"
                  type="number"
                  class="form-control"
                >
              </div>
            </div>
          </div>

        </div>

        <div v-if="coupon.type.toString()==='1'">
          <h4>Aplicables</h4>
          <div class="row">
            <div class="col-12">
              <div class="btn-group w-100">
                <button
                  class="btn btn-outline-primary"
                  :class="appliedType.toString()==='1'? 'active': ''"
                  @click="loadTyppeables(1)"
                >
                  Productos
                </button>
                <button
                  class="btn btn-outline-primary"
                  :class="appliedType.toString()==='2'? 'active': ''"
                  @click="loadTyppeables(2)"
                >
                  Categorías
                </button>
                <button
                  class="btn btn-outline-primary"
                  :class="appliedType.toString()==='3'? 'active': ''"
                  @click="loadTyppeables(3)"
                >
                  Marcas
                </button>
                <button
                  class="btn btn-outline-primary"
                  :class="appliedType.toString()==='4'? 'active': ''"
                  @click="loadTyppeables(4)"
                >
                  Etiquetas
                </button>
              </div>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-12">
              <div>
                <div v-if="appliedType.toString()==='1'">
                  <h5 class="text-center">
                    Selecciona los productos con descuento
                  </h5>
                </div>
                <div v-if="appliedType.toString()==='2'">
                  <h5 class="text-center">
                    Selecciona las categorías con descuento
                  </h5>
                </div>
                <div v-if="appliedType.toString()==='3'">
                  <h5 class="text-center">
                    Selecciona las marcas con descuento
                  </h5>
                </div>
                <div v-if="appliedType.toString()==='4'">
                  <h5 class="text-center">
                    Selecciona las etiquetas con descuento
                  </h5>
                </div>

                <div class="form-group">
                  <label>Filtrar</label>
                  <input
                    v-model="typpeableFilter"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div
                  class="bg-light"
                  style="height: 350px;overflow: auto;"
                >
                  <div v-if="appliedType.toString()==='1'">
                    <div
                      v-for="product of products"
                      :key="product.getAttr('id')"
                    >
                      <div
                        v-if="product.getAttr('name_es', false).toLowerCase().includes(typpeableFilter.toLowerCase())"
                        class="card m-1 cursor-pointer"
                        :class="typpeablesSelected.includes(product.getAttr('id')) ? 'border-primary' : 'border-white'"
                        @click="toggleTyppeablesSelected(product)"
                      >
                        <div class="card-body">
                          <product-info-component
                            :model="product"
                            :value="product.getAttr('name_es', false)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="appliedType.toString()==='2'">
                    <div
                      v-for="category of categories"
                      :key="category.getAttr('id')"
                    >
                      <div
                        v-if="category.getAttr('name_es', false).toLowerCase().includes(typpeableFilter.toLowerCase())"
                        class="card m-1 cursor-pointer"
                        :class="typpeablesSelected.includes(category.getAttr('id')) ? 'border-primary' : 'border-white'"
                        @click="toggleTyppeablesSelected(category)"
                      >
                        <div class="card-body">
                          {{ category.getAttr('name_es') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="appliedType.toString()==='3'">
                    <div
                      v-for="brand of brands"
                      :key="brand.getAttr('id')"
                    >
                      <div
                        v-if="brand.getAttr('name_es', false).toLowerCase().includes(typpeableFilter.toLowerCase())"
                        class="card m-1 cursor-pointer"
                        :class="typpeablesSelected.includes(brand.getAttr('id')) ? 'border-primary' : 'border-white'"
                        @click="toggleTyppeablesSelected(brand)"
                      >
                        <div class="card-body">
                          {{ brand.getAttr('name_es') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="appliedType.toString()==='4'">
                    <div
                      v-for="tag of tags"
                      :key="tag.getAttr('id')"
                    >
                      <div
                        v-if="tag.getAttr('name_es', false).toLowerCase().includes(typpeableFilter.toLowerCase())"
                        class="card m-1 cursor-pointer"
                        :class="typpeablesSelected.includes(tag.getAttr('id')) ? 'border-primary' : 'border-white'"
                        @click="toggleTyppeablesSelected(tag)"
                      >
                        <div class="card-body">
                          {{ tag.getAttr('name_es') }}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

        <h4>Información adicional</h4>
        <div class="row">
          <div class="col-6">
            <div
              class="form-group"
            >
              <label>Fecha de inicio</label>
              <!--              <input
                v-model="coupon.start_date"
                type="date"
                class="form-control"
              >-->
              <flat-pickr
                v-model="coupon.start_date"
                class="form-control"
                :config="{ enableTime: false,dateFormat: 'Y-m-d', allowInput: true}"
              />
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
            >
              <label>Fecha de finalización (dejar vacío si es sin vigencia)</label>
              <!--              <input
                v-model="coupon.end_date"
                type="date"
                class="form-control"
              >-->
              <flat-pickr
                v-model="coupon.end_date"
                class="form-control"
                :config="{ enableTime: false,dateFormat: 'Y-m-d', allowInput: true}"
              />
            </div>
          </div>
        </div>

        <div
          class="form-group"
        >
          <label>Monto mínimo de compra (dejar vacío si no hay monto límite)</label>
          <input
            v-model="coupon.min_purchase_amount"
            type="number"
            class="form-control"
          >
        </div>

        <div
          class="form-group"
        >
          <label>Cantidad de uso máximo</label>
          <input
            v-model="coupon.max_uses"
            type="number"
            class="form-control mb-1"
          >

          <b-form-checkbox
            v-model="coupon.single_use"
            switch
          >
            Limitar a uno por cliente
          </b-form-checkbox>

          <b-form-checkbox
            v-if="coupon.type.toString()==='2'"
            v-model="coupon.free_shipping_included"
            switch
          >
            Con envío gratis
          </b-form-checkbox>
        </div>

        <button
          class="btn btn-primary"
          @click="save"
        >
          Guardar
        </button>
      </div>
      <div
        v-if="loading || !coupon.type"
        class="d-flex align-items-center justify-content-center"
      >
        <b-spinner />
      </div>
    </div>
  </div>
</template>

<script>

import CouponTypeSelecModal from '@/views/components/modals/CouponTypeSelecModal.vue'
import { BSpinner, BFormCheckbox, BBadge } from 'bootstrap-vue'
import GeneralService from '@/dc-it/services/GeneralService'
import flatPickr from 'vue-flatpickr-component'
import Product from '@/dc-it/models/Product'
import Category from '@/dc-it/models/Category'
import Brand from '@/dc-it/models/Brand'
import ProductInfoComponent from '@/views/components/ProductInfoComponent.vue'
import Tag from '@/dc-it/models/Tag'
import Coupon from '@/dc-it/models/Coupon'

export default {
  name: 'CouponFormView',
  components: {
    CouponTypeSelecModal, BSpinner, BFormCheckbox, BBadge, flatPickr, ProductInfoComponent,
  },
  data() {
    return {
      loading: false,
      types: {
        1: 'Descuento de producto',
        2: 'Descuento en la orden',
        3: 'Envío gratis',
      },
      discountTypes: [
        { type: 1, text: 'Monto fijo' },
        { type: 2, text: 'Porcentaje' },
      ],
      appliedType: 0, // 1=Productos, 2=categorías, 3=marcas
      coupon: {
        id: null,
        name: '',
        code: '',
        discount_type: 1,
        discount_amount: 1,
        start_date: null,
        end_date: null,
        type: null,
        min_purchase_amount: null,
        max_uses: null,
        single_use: true,
        automatic_application: false,
        free_shipping_included: false,
      },
      products: [],
      categories: [],
      brands: [],
      tags: [],
      typpeablesSelected: [],
      typpeableFilter: '',
      typeables: {
        1: 'App\\Models\\Product',
        2: 'App\\Models\\Category',
        3: 'App\\Models\\Brand',
        4: 'App\\Models\\Tag',
      },
    }
  },
  mounted() {
    this.loadInitData()
  },
  methods: {
    async loadInitData() {
      if (this.$route.params.id) {
        this.loading = true
        const coupons = await Coupon.where('id', '=', this.$route.params.id).get()
        if (coupons.length !== 1) {
          this.loading = false
          this.$router.replace('/not-found')
          return
        }
        const c = coupons[0]
        this.coupon = {
          id: c.getAttr('id'),
          name: c.getAttr('name'),
          code: c.getAttr('code'),
          discount_type: c.getAttr('discount_type'),
          discount_amount: c.getAttr('discount_amount'),
          start_date: c.getAttr('start_date'),
          end_date: c.getAttr('end_date'),
          type: c.getAttr('type'),
          min_purchase_amount: c.getAttr('min_purchase_amount'),
          max_uses: c.getAttr('max_uses'),
          single_use: c.getAttr('single_use', false).toString() === '1',
          automatic_application: c.getAttr('automatic_application', false).toString() === '1',
          free_shipping_included: c.getAttr('free_shipping_included', false).toString() === '1',
        }
        this.selectedType(this.coupon.type)
        this.loading = false
      } else {
        this.$refs.refFormModal.show()
      }
    },
    selectedType(type) {
      this.coupon.type = type
      if (type.toString() === '1') {
        this.loadTyppeables(1)
      }
    },
    save() {
      if (this.coupon.type.toString() === '1') {
        if (this.typpeablesSelected.length === 0) {
          alert('Debe seleccionar por lo menos un producto, categoría o marca')
          return
        }
        const typpeables = []
        this.typpeablesSelected.forEach(item => {
          typpeables.push({
            id: item,
            type: this.typeables[this.appliedType],
          })
        })

        this.coupon.typeables = typpeables
      }
      this.loading = true
      GeneralService.getInstance().save('coupons', this.coupon).then(() => {
        // eslint-disable-next-line no-restricted-globals
        location.href = '/coupons'
      })
    },
    toggleTyppeablesSelected(typeable) {
      if (this.typpeablesSelected.includes(typeable.getAttr('id'))) {
        this.typpeablesSelected.splice(this.typpeablesSelected.indexOf(typeable.getAttr('id')), 1)
      } else {
        this.typpeablesSelected.push(typeable.getAttr('id'))
      }
    },
    loadTyppeables(type) {
      if (type.toString() === this.appliedType.toString()) {
        return
      }
      this.appliedType = type
      this.typpeablesSelected = []
      switch (type) {
        case 1: // productos
          Product.where('status', '=', 2).orWhere('status', '=', 4).get().then(products => {
            this.products = products
          })
          break
        case 2: // categorías
          Category.get().then(categories => {
            this.categories = categories
          })
          break
        case 3: // marcas
          Brand.get().then(brands => {
            this.brands = brands
          })
          break
        case 4: // etiquetas
          Tag.get().then(tags => {
            this.tags = tags
          })
          break
        default:
          this.loadTyppeables(1)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

import helpers from '@/dc-it/services/helpers'
import HTMLField from '@/dc-it/types/HTMLField'
import ProductInfoComponent from '@/views/components/ProductInfoComponent.vue'
import Model from './Model'

import Field from '../types/Field'
import Relation from '../types/Relation'
import BooleanField from '../types/BooleanField'
import Brand from './Brand'
import Customer from './Customer'
import Category from './Category'
import ProductStatus from './ProductStatus'

export default class Product extends Model {
    endpoint = 'products';

    related = ['brand', 'category', 'subcategory', 'multimedia', 'product_status', 'customer', 'product_variant_groups.product_variants'];

    fields = [
      new Field('id', 'Código').disabled().hide(),
      new Field('SKU', 'SKU'),
      new Field('name_es', 'Nombre (español)').renderComponent(ProductInfoComponent),
      new Field('name_en', 'Nombre (inglés)').hide(),
      new Relation('brand', 'Marca', Brand, 'name_es'),
      new Relation('category', 'Categoría', Category, 'name_es').hide(),
      new Relation('subcategory', 'Subcategoría', Category, 'name_es').hide(),
      new Field('size', 'Tamaño').hide(),
      new BooleanField('apply_cashback', 'Aplica cashback').hide(),
      new HTMLField('description_es', 'Descripción (español)').hide(),
      new HTMLField('description_en', 'Descripción (ingles)').hide(),
      new Relation('product_status', 'Estado del producto', ProductStatus, 'name_es'),
      new BooleanField('promotable', 'Promocionable').hide(),
      new Field('public_price', 'Precio público', 'number').applyMask(val => `$${helpers.numberFormat(val)}`),
      new Field('net_price', 'Precio neto', 'number').hide().applyMask(val => `$${helpers.numberFormat(val)}`),
      new BooleanField('internal_product', 'Producto interno').hide(),
      new Relation('customer', 'Cliente', Customer, 'first_name').hide(),
      new Field('tiktok_url', 'URL Tiktok').hide(),
      new Field('instagram_url', 'URL Instagram').hide(),
      new Field('youtube_url', 'URL YouTube').hide(),
      new Field('stock', 'Stock', 'number').hide(),
      new Field('status', 'Estado').hide(),
      new Field('shipment_form', 'Enviado desde').hide(),
      new Field('tag_text_es', 'Etiqueta (español)').hide(),
      new Field('tag_text_en', 'Etiqueta (ingles)').hide(),
      new Field('dimensions', 'Medidas').hide(),

    ]

    clone = () => Product;
}

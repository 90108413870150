import Relation from '@/dc-it/types/Relation'
import BooleanField from '../types/BooleanField'
import Field from '../types/Field'
import Model from './Model'

export default class Category extends Model {
    endpoint = 'categories';

    related = ['category']

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name_es', 'Nombre (español)'),
      new Field('name_en', 'Nombre (inglés)'),
      new BooleanField('apply_sizes', '¿Puede tener tallas/tamaños?').hide(),
      new Relation('category', 'Categoría padre', Category, 'name_es').setClearable(),
      new BooleanField('entrupy_certificate', '¿Puede tener certificado Entrupy?').hide(),
    ]

    clone = () => Category;
}

import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Select from '@/dc-it/types/Select'

export default class Coupon extends Model {
    endpoint = 'coupons'

    types = {
      1: 'Descuento en producto',
      2: 'Descuento de orden',
      3: 'Envío gratis',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
      new Field('code', 'Código de uso'),
      new Select('type', 'Tipo de cupón', this.types),
      new Field('start_date', 'Fecha inicial'),
      new Field('end_date', 'Fecha final'),
      new Field('max_uses', 'Usos máximos'),
      new Field('uses', 'Usos'),
    ]

    clone=() => Coupon;
}
